<template>
  <div>
    <v-overlay :value="isLoadingSpinner" :opacity="`0.5`" zIndex="99999"
      ><v-progress-circular
        indeterminate
        size="70"
        width="7"
        color="primary"
      ></v-progress-circular
    ></v-overlay>
    <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
      {{ text_snackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <goDialogMaterial
      v-model="showGoDialog"
      :reloadTable="getDataFromApi"
      :row="selectedRow"
      :importExcelChainPerMaterialTemplatePathName="
        importExcelChainPerMaterialTemplatePathName
      "
    />
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ formTitle }}</span>
          </v-card-title>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <picture-input
                  :crop="false"
                  class="my-picture-input"
                  ref="pictureInput"
                  width="500"
                  height="300"
                  size="5"
                  :prefill="
                    getImageUri(
                      selected.materialPhoto.static
                        ? selected.materialPhoto.static
                        : null
                    )
                  "
                  accept="image/jpeg,image/png"
                  button-class="btn btn-outline-info"
                  aspect="Landscape"
                  :custom-strings="{
                    upload: this.$t('uploading_image'),
                    change: this.$t('change'),
                    drag: this.$t('Photo')
                  }"
                  @change="onPicture"
                >
                </picture-input>
                <small style="text-align: center; font-size: 11px; "
                  >5MB max.</small
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="selected.name"
                  v-bind:label="$t('brands.name')"
                  required
                  :rules="requiredRules"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="selected.materialCode"
                  v-bind:label="`${$t('Material')} Code`"
                  required
                  :rules="requiredRules"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="selected.description"
                  v-bind:label="$t('brands.description')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="selected.salesArguments"
                  v-bind:label="$t('Sales Arguments')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="selected.executionInstruction"
                  v-bind:label="$t('Execution Instruction')"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="selected.mHeight"
                  v-bind:label="$t('Height')"
                  :type="'number'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="selected.mWidth"
                  v-bind:label="$t('Width')"
                  :type="'number'"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model="selected.mLength"
                  v-bind:label="$t('Length')"
                  :type="'number'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.status"
                  v-bind:label="$t('status')"
                  :items="statusValues"
                  item-text="label"
                  item-value="value"
                  filled
                  required
                  :rules="requiredRules"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.brandId"
                  v-bind:label="$t('brands.brand')"
                  item-text="name"
                  item-value="id"
                  :items="filtered_brands"
                  hide-no-data
                  hide-selected
                  filled
                  required
                  :rules="requiredRules"
                  @change="onChangeBrand"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.subBrandId"
                  v-bind:label="$t('brands.sub_brand')"
                  item-text="name"
                  item-value="id"
                  :items="filtered_subbrands"
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.productFamilyId"
                  v-bind:label="`${$t('Product')} ${$t('brands.family')}`"
                  item-text="name"
                  item-value="id"
                  :items="filtered_product_families"
                  filled
                  clearable
                  @change="onChangeFamily('PRODUCT_FAMILY')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.materialFamilyId"
                  v-bind:label="`${$t('Material')} ${$t('brands.family')}`"
                  item-text="name"
                  item-value="id"
                  :items="filtered_material_families"
                  filled
                  required
                  :rules="requiredRules"
                  clearable
                  @change="onChangeFamily('MATERIAL_FAMILY')"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.productSubFamilyId"
                  v-bind:label="`${$t('Product')} Sub ${$t('brands.family')}`"
                  item-text="name"
                  item-value="id"
                  :items="filtered_product_subfamilies"
                  filled
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-autocomplete
                  v-model="selected.materialSubFamilyId"
                  v-bind:label="`${$t('Material')} Sub ${$t('brands.family')}`"
                  item-text="name"
                  item-value="id"
                  :items="filtered_material_subfamilies"
                  filled
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="onSaveClick(selected)"
              :loading="isSaving"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>
        <div
          class="d-flex justify-content-between align-center"
          style="width: 100%"
        >
          <span>{{ $t("Material") }}</span>
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="downloadExcelTemplateAPI('MATERIALS')"
              class="ml-2"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="ml-2"
              :loading="isSelecting"
              @click="onUploadExcelClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonText }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn color="warning" dark class="ml-2" @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12" sm="12" md="12">
            <ve-table
              id="data-table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="tableData"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaltableData"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import _ from "lodash";
import { logError, logInfo } from "@/utils.js";
import { downloadCSVFile } from "@/csv.js";
import goDialogMaterial from "./goDialogMaterial.vue";
import PictureInput from "vue-picture-input";
import download from "js-file-download";

export default {
  name: "Material",
  components: {
    goDialogMaterial,
    PictureInput
  },
  data: function() {
    return {
      tableData: [],
      totaltableData: 0,
      statusValues: [],
      repeatsUnitValues: [],
      importExcelChainPerMaterialTemplatePathName: null,
      /********** vue-easytable options **********/
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        name: {},
        materialCode: {},
        productCode: {},
        description: {},
        brandId: {},
        subBrandId: {},
        status: {},
        salesArguments: {},
        executionInstruction: {},
        mWidth: {},
        mHeight: {},
        mLength: {},
        productFamilyId: {},
        productSubFamilyId: {},
        materialFamilyId: {},
        materialSubFamilyId: {}
      },
      filterable_col_list: [
        "name",
        "materialCode",
        "productCode",
        "description",
        "brandId",
        "subBrandId",
        "status",
        "salesArguments",
        "executionInstruction",
        "mWidth",
        "mHeight",
        "mLength",
        "productFamilyId",
        "productSubFamilyId",
        "materialFamilyId",
        "materialSubFamilyId"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      showEmpty: false,
      filterlist_fetch_url: "brands/material/getfilterlist",

      /********** form-validation options **********/
      valid: true,
      requiredRules: [v => !!v || "Required"],
      numberRules: [
        // (v) => !!v || "Required",
        v => !v || v >= 0 || "Este campo debe estar por encima de 0"
        // (v) => (v && v <= 100) || "This field should not be above 100",
      ],
      selected: null,
      editedIndex: -1,

      /********** create or edit dialog options **********/
      showEdit: false,
      isSaving: false,
      date_menu_start: false,
      date_menu_end: false,
      reactive_start: false,
      reactive_end: false,

      search_product: null,
      isProductSearching: false,
      filtered_products: [],

      showGoDialog: false,
      selectedRow: null,

      search_brand: null,
      isBrandSearching: false,
      filtered_brands: [],

      filtered_product_families: [],
      filtered_product_subfamilies: [],
      filtered_material_families: [],
      filtered_material_subfamilies: [],
      filtered_subbrands: [],

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple",
      /*****************************  Loading Spinner Overlay  ***********************************/
      isLoadingSpinner: false
    };
  },
  watch: {
    async search_product(val) {
      await this.search_product_list(val);
    },
    async search_brand(val) {
      await this.search_brand_list(val);
    }
  },
  computed: {
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    },
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    // selectedProduct() {
    //   let selectedProduct = null;
    //   if (this.selected && this.selected.productId) {
    //     let filteredProduct = this.filtered_products.filter(
    //       (el) => el.id === this.selected.productId
    //     );
    //     if (filteredProduct.length > 0) {
    //       selectedProduct = filteredProduct[0];
    //       selectedProduct["brandName"] = selectedProduct.brand
    //         ? selectedProduct.brand.name
    //         : "";
    //       selectedProduct["familyName"] = selectedProduct.family
    //         ? selectedProduct.family.name
    //         : "";
    //     }
    //   }
    //   return selectedProduct;
    // },
    col_id() {
      return {
        key: "id",
        title: "ID",
        field: "id",
        align: "left",
        width: 70,
        sortBy: "",
        fixed: "left"
      };
    },
    col_name() {
      return {
        key: "name",
        title: this.$t("Name"),
        field: "name",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_materialCode() {
      return {
        key: "materialCode",
        title: `${this.$t("Material")} Code`,
        field: "materialCode",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["materialCode"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`materialCode`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["materialCode"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "materialCode")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_productCode() {
      return {
        key: "productCode",
        title: `${this.$t("Product")} Code`,
        field: "productCode",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.productCode}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["productCode"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`productCode`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["productCode"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "productCode")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_description() {
      return {
        key: "description",
        title: `${this.$t("brands.description")}`,
        field: "description",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["description"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`description`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["description"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "description")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_brand() {
      return {
        key: "brandId",
        title: this.$t("brands.brand"),
        field: "brandId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.brand?.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brandId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brandId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brandId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brandId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_subBrand() {
      return {
        key: "subBrandId",
        title: this.$t("brands.sub_brand"),
        field: "subBrandId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.subbrand?.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["subBrandId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`subBrandId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["subBrandId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "subBrandId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_status() {
      return {
        key: "status",
        title: `${this.$t("status")}`,
        field: "status",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.status}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["status"]}
                  dataList={this.statusValues}
                  filterCriteria={this.filterCriteria["status"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "status")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_edit() {
      return {
        key: "edit",
        title: this.$t("salesforce.Edit"),
        field: "actions",
        align: "center",
        width: 100,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-icon
                medium
                color="primary"
                class="mr-2"
                on-click={() => this.onItemEdit(row)}
              >
                mdi-pencil
              </v-icon>
            </div>
          );
        }
      };
    },
    col_go() {
      return {
        key: "go",
        title: this.$t("chain"),
        field: "go",
        align: "center",
        width: 80,
        fixed: "right",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              <v-btn
                color="blue darken-1"
                text
                on-click={() => this.onItemGo(row)}
              >
                <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
              </v-btn>
            </div>
          );
        }
      };
    },
    col_materialPhoto() {
      return {
        key: "p",
        title: this.$t("brands.Photos"),
        field: "photo",
        align: "left",
        width: 150,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          if (row.materialPhoto !== null) {
            let thumbnail_url = this.getThumbnailUri(row.materialPhoto);
            return (
              <img
                style="border: 1px solid #aaa; max-width: 100%"
                src={thumbnail_url}
                alt="photo"
              />
            );
          } else {
            return <span>&nbsp;</span>;
          }
        }
      };
    },
    col_salesArguments() {
      return {
        key: "salesArguments",
        title: this.$t("Sales Arguments"),
        field: "salesArguments",
        align: "left",
        width: 200,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["salesArguments"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`salesArguments`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["salesArguments"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "salesArguments")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_executionInstruction() {
      return {
        key: "executionInstruction",
        title: this.$t("Execution Instruction"),
        field: "executionInstruction",
        align: "left",
        width: 230,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["executionInstruction"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`executionInstruction`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["executionInstruction"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "executionInstruction")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_mWidth() {
      return {
        key: "mWidth",
        title: this.$t("Width"),
        field: "mWidth",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["mWidth"]["from"]}
                  value={this.filterCriteria["mWidth"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["mWidth"]["to"]}
                  value={this.filterCriteria["mWidth"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "mWidth")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_mHeight() {
      return {
        key: "mHeight",
        title: this.$t("Height"),
        field: "mHeight",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["mHeight"]["from"]}
                  value={this.filterCriteria["mHeight"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["mHeight"]["to"]}
                  value={this.filterCriteria["mHeight"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "mHeight")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_mLength() {
      return {
        key: "mLength",
        title: this.$t("Length"),
        field: "mLength",
        align: "left",
        width: 150,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["mLength"]["from"]}
                  value={this.filterCriteria["mLength"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="number"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["mLength"]["to"]}
                  value={this.filterCriteria["mLength"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "mLength")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_productFamilyId() {
      return {
        key: "productFamilyId",
        title: `${this.$t("Product")} ${this.$t("brands.family")}`,
        field: "productFamilyId",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.productMainFamily?.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["productFamilyId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`productFamilyId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["productFamilyId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "productFamilyId")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_productSubFamilyId() {
      return {
        key: "productSubFamilyId",
        title: `${this.$t("Product")} Sub ${this.$t("brands.family")}`,
        field: "productSubFamilyId",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.productSubFamily?.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["productSubFamilyId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`productSubFamilyId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["productSubFamilyId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "productSubFamilyId")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_materialFamilyId() {
      return {
        key: "materialFamilyId",
        title: `${this.$t("Material")} ${this.$t("brands.family")}`,
        field: "materialFamilyId",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.materialMainFamily?.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["materialFamilyId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`materialFamilyId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["materialFamilyId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "materialFamilyId")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_materialSubFamilyId() {
      return {
        key: "materialSubFamilyId",
        title: `${this.$t("Material")} Sub ${this.$t("brands.family")}`,
        field: "materialSubFamilyId",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.materialSubFamily?.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["materialSubFamilyId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`materialSubFamilyId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["materialSubFamilyId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "materialSubFamilyId")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    columns() {
      return [
        this.col_id,
        this.col_name,
        this.col_brand,
        this.col_subBrand,
        this.col_materialCode,
        // this.col_productCode,
        this.col_description,
        this.col_salesArguments,
        this.col_executionInstruction,
        this.col_mHeight,
        this.col_mWidth,
        this.col_mLength,
        this.col_productFamilyId,
        this.col_productSubFamilyId,
        this.col_materialFamilyId,
        this.col_materialSubFamilyId,
        this.col_status,
        this.col_materialPhoto,
        this.col_edit,
        this.col_go
      ];
    }
  },
  methods: {
    async onItemGo(row) {
      await this.getDataFromApi();
      this.selectedRow = row;
      this.showGoDialog = true;
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#data-table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      this.filterCriteria[cancelFilterKey] = {};
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "date") {
            let convert_value = {
              from: "",
              to: ""
            };
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = value["from"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = value["to"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      let filter_columns = document.querySelectorAll(
        "#data-table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      if (this.isteam !== undefined) {
        query_params_string += "&isteam=" + this.isteam;
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "brands/material";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.tableData = resp.data;
        if (this.tableData.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaltableData = resp.total;
        this.importExcelChainPerMaterialTemplatePathName =
          resp.importExcelChainPerMaterialTemplatePathName;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async onItemEdit(item) {
      console.log(item);
      this.selected = await this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.selected.materialPhoto.img = null;
      this.showEdit = true;
    },
    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    onPicture(img) {
      this.selected.materialPhoto.img = this.$refs["pictureInput"].file;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    async search_product_list(val) {
      let fetch_url = `brands/material/getfilterlist?column=selectableproducts&isFullText=true&filterValue=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_products = resp.data;
    },
    async search_brand_list(val) {
      let fetch_url = `brands/material/getfilterlist?column=selectablebrands&isFullText=true&filterValue=${val}`;
      const resp = await ApiService.get(fetch_url);
      this.filtered_brands = resp.data;
    },
    async createSelectedModel(item) {
      console.log(item);
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        materialCode: _.get(item, "materialCode"),
        // productId: _.get(item, "productId"),
        product: _.get(item, "product"),
        pro_sub_families: _.get(item, "pro_sub_families"),
        subFamilyName: _.get(item, "subFamilyName"),
        description: _.get(item, "description"),
        status: _.get(item, "status"),
        salesArguments: _.get(item, "salesArguments"),
        executionInstruction: _.get(item, "executionInstruction"),
        mWidth: _.get(item, "mWidth"),
        mHeight: _.get(item, "mHeight"),
        mLength: _.get(item, "mLength"),
        materialPhoto: {
          img: null,
          static: _.get(item, "materialPhoto")
        },
        brandId: _.get(item, "brandId"),
        brand: _.get(item, "brand"),
        subBrandId: _.get(item, "subBrandId"),
        subbrand: _.get(item, "subbrand"),

        productFamilyId: _.get(item, "productFamilyId"),
        productMainFamily: _.get(item, "productMainFamily"),
        productSubFamilyId: _.get(item, "productSubFamilyId"),
        productSubFamily: _.get(item, "productSubFamily"),

        materialFamilyId: _.get(item, "materialFamilyId"),
        materialMainFamily: _.get(item, "materialMainFamily"),
        materialSubFamilyId: _.get(item, "materialSubFamilyId"),
        materialSubFamily: _.get(item, "materialSubFamily")
      };
      if (model.status === undefined) model.status = "active";
      // if (model.product) {
      //   this.filtered_products = [model.product];
      // } else await this.search_product_list("");

      this.filtered_product_families = [];
      this.filtered_product_subfamilies = [];
      this.filtered_subbrands = [];

      await this.search_brand_list("");

      if (model.brand) {
        await this.fetchlist_subbrands(model.brandId, 0);
        await this.fetchlist_product_families("BRAND", model.brandId, 0);
        await this.fetchlist_material_families("BRAND", model.brandId, 0);
      }
      if (model.productMainFamily) {
        await this.fetchlist_product_families(
          "PRODUCT_FAMILY",
          model.brandId,
          model.productFamilyId
        );
      }
      if (model.materialMainFamily) {
        await this.fetchlist_material_families(
          "MATERIAL_FAMILY",
          model.brandId,
          model.materialFamilyId
        );
      }

      if (model.id === undefined) {
        if (this.$refs["pictureInput"] !== undefined) {
          this.$refs["pictureInput"].removeImage();
        }
      }
      console.log(model);
      return model;
    },
    // onChangeProduct() {
    //   if (this.selected && this.selected.productId) {
    //     let filteredProduct = this.filtered_products.filter(
    //       (el) => el.id === this.selected.productId
    //     );
    //     if (filteredProduct.length > 0) {
    //       this.selected.product = filteredProduct[0];
    //       this.selected.product["brandName"] = this.selected.product.brand
    //         ? this.selected.product.brand.name
    //         : "";
    //       this.selected.product["familyName"] = this.selected.product.family
    //         ? this.selected.product.family.name
    //         : "";
    //     }
    //   }
    //   console.log(this.selected);
    // },
    async onChangeBrand() {
      console.log(this.selected.brandId);
      this.selected.productFamilyId = null;
      this.selected.productSubFamilyId = null;
      this.selected.subBrandId = null;
      this.filtered_product_families = [];
      this.filtered_product_subfamilies = [];
      this.filtered_subbrands = [];

      this.selected.materialFamilyId = null;
      this.selected.materialSubFamilyId = null;
      this.filtered_material_families = [];
      this.filtered_material_subfamilies = [];

      await this.fetchlist_subbrands(this.selected.brandId, 0);
      await this.fetchlist_product_families("BRAND", this.selected.brandId, 0);
      await this.fetchlist_material_families("BRAND", this.selected.brandId, 0);
    },
    async fetchlist_subbrands(brandId, parentId) {
      try {
        let fetch_url = `brands/material/getfilterlist?column=selectablesubbrands&parentId=${parentId}&brandId=${brandId}&filterValue=`;
        const resp = await ApiService.get(fetch_url);
        this.filtered_subbrands = resp.data;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchlist_product_families(changedCategory, brandId, parentId) {
      try {
        let fetch_url = `brands/material/getfilterlist?column=selectablefamilies&familyCategory=PRODUCT&parentId=${parentId}&brandId=${brandId}&filterValue=`;
        const resp = await ApiService.get(fetch_url);
        if (changedCategory === "BRAND") {
          this.filtered_product_families = resp.data;
        } else {
          this.filtered_product_subfamilies = resp.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchlist_material_families(changedCategory, brandId, parentId) {
      try {
        let fetch_url = `brands/material/getfilterlist?column=selectablefamilies&familyCategory=MATERIAL&parentId=${parentId}&brandId=${brandId}&filterValue=`;
        const resp = await ApiService.get(fetch_url);
        if (changedCategory === "BRAND") {
          this.filtered_material_families = resp.data;
        } else {
          this.filtered_material_subfamilies = resp.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onChangeFamily(changedCategory) {
      if (changedCategory === "PRODUCT_FAMILY") {
        this.selected.productSubFamilyId = null;
        this.filtered_product_subfamilies = [];
        await this.fetchlist_product_families(
          "PRODUCT_FAMILY",
          this.selected.brandId,
          this.selected.productFamilyId
        );
      } else if (changedCategory === "MATERIAL_FAMILY") {
        this.selected.materialSubFamilyId = null;
        this.filtered_material_subfamilies = [];
        await this.fetchlist_material_families(
          "MATERIAL_FAMILY",
          this.selected.brandId,
          this.selected.materialFamilyId
        );
      }
    },
    async onSaveClick(item) {
      // this.selected.materialPhoto.img = this.$refs["pictureInput"].file;
      if (this.$refs.form.validate()) {
        let body = _.omit(item, [
          "product",
          "pro_sub_families",
          "subFamilyName",
          "materialPhoto"
        ]);
        try {
          this.isSaving = true;
          console.log(body);
          let img = item.materialPhoto.img;
          if (img) {
            let { data } = await ApiService.uploadImage(img);
            logInfo("imagen subida");
            body.photoId = data.id;
          }
          let status = null;
          if (body.id) {
            status = await ApiService.put(`brands/material/${body.id}`, body);
          } else {
            status = await ApiService.post(`brands/material`, body);
          }
          logInfo("éxito");
          this.showEdit = false;
          this.isSaving = false;
          this.getDataFromApi();
        } catch (error) {
          this.showEdit = false;
          this.isSaving = false;
        }
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "brands/material/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "materials.xlsx");
      this.close();
    },
    onUploadExcelClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      if (
        window.confirm(
          `¿Está seguro de que desea cargar este archivo de Excel?`
        )
      ) {
        this.isSelecting = true;
        this.isLoadingSpinner = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        try {
          const response = await ApiService.post(
            `brands/material/uploadexcel`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelecting = false;
          this.isLoadingSpinner = false;
          this.getDataFromApi();
        } catch (error) {
          this.isSelecting = false;
          this.isLoadingSpinner = false;
        }
      }
    },
    init() {
      this.statusValues = [
        { value: "active", label: "Active" },
        { value: "inactive", label: "Inactive" }
      ];
      this.getDataFromApi();
    }
  },

  async mounted() {
    this.init();
  }
};
</script>

<style></style>
